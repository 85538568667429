.contactus {
    padding-top: 150px;
    padding-bottom: 50px;
    min-height: calc(100vh - 157px);
    h1{
        font-family: "runings";
        font-size: 40px;
    }
    p {
        color: #ffffff8f;
        font-size: 20px;
    }
}

.contact-box {
    background-color: #1A1A1A;
    border-radius: 20px;
}