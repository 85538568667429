.fighting-promotions {
    .hero-section {
        min-height: 100vh;
        background-image: url(/assets/promotions-hero.jpg);
        background-size: cover;
        background-position: center bottom;
        color: #fff;
        padding-top:250px;
        h1{
            font-family: "runings";
            font-size: 45px;
        }
        hr {
            border-color: #fff;
            opacity: 1;
        }
        .sub-text  {
            font-size: 20px;
        }
        
    }
    .section2 {
        padding: 80px 0;
        .red-line {
            width: 320px;
        }
        h2{
            font-family: "runings";
            font-size: 45px;
        }
        p {
            color: #ffffff8f;
            font-size: 20px;
        }
        .features {
            img {
                max-height: 130px;
            }
            .name {
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .carousel-section {
        h3 {
            font-size: 45px;
            font-weight: 600;
        }
        p {
            color: #ffffff8f;
            font-size: 18px;
        }
    }
    .section3 {
        h3 {
            font-size: 45px;
            font-weight: 600;
        }
        .features-box {
            background-color: #520F0F;
            border-radius: 30px;
        }
    }
    .section4 {
        h3 {
            font-size: 35px;
            font-weight: 600;
        }
        .sub-text {
            font-size: 22px;
        }
        h6 {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .section5 {
        h3 {
            font-size: 45px;
            font-weight: 600;
        }
        p {
            color: #ffffff8f;
        }
        .gfl-box {
            background-image: linear-gradient(180deg, rgba(148,29,29,0.32) 0%, #000000 100%);
            box-shadow: 0 5px 7px 0 rgba(255,255,255,0.20);
            border-radius: 16px;
            padding: 80px;
            p {
                font-size: 20px;
            }
        }
    }
    .section6 {
        background-image: url(/assets/promotions-img1.jpg);
        background-size: cover;
        background-position: center top;
        height: 500px;
        h3 {
            font-size: 40px;
            font-weight: 600;
        }
    }
}

.modal-form-heading{
    display: flex;
    justify-content: center;
    font-family: "runings";
    font-size: 45px;
}

.MuiBox-root.css-jme4iv {
    max-width: 1305px !important;
}

@media only screen and (max-width: 600px) {
    .fighting-promotions .hero-section {
        min-height: 80vh;
    }
    .fighting-promotions .hero-section h1 {
        font-size: 20px;
    }
    .fighting-promotions .hero-section .sub-text {
        font-size: 16px;
    }
    .fighting-promotions .hero-section .btn-primary {
        font-size: 12px;
    }
    .fighting-promotions .hero-section .btn-outline-primary {
        font-size: 12px;
    }
    .fighting-promotions .section2 h2 {
        font-size: 20px;
    }
    .fighting-promotions .section2 p {
        font-size: 16px;
    }
    .fighting-promotions .section2 .features img {
        max-height: 60px;
    }
    .fighting-promotions .section2 .features .name {
        font-size: 14px;
    }
    .fighting-promotions .carousel-section h3 {
        font-size: 24px;
    }
    .fighting-promotions .section3 h3 {
        font-size: 24px;
    }
    .fighting-promotions .features-box {
        h4 {
            font-size: 18px;
        }
        img {
            height: 60px;
        }
    }
    .fighting-promotions .section4 h3 {
        font-size: 24px;
        padding-top: 20px;
    }
    .fighting-promotions .section4 .sub-text {
        font-size: 16px;
    }
    .fighting-promotions .section4 .features img {
        height: 80px;
    }
    .fighting-promotions .section4 h6 {
        font-size: 18px;
    }
    .fighting-promotions .section5 h3 {
        font-size: 24px;
    }
    .fighting-promotions .section5 .gfl-box {
        padding: 25px;
        text-align: center;
        img {
            height: 100px;
            padding-bottom: 20px;
        }
    }
    .fighting-promotions .section6 {
        height: 300px;
        h3 {
            font-size: 24px;
        }
    }
    .modal-form-heading{
        font-size: 26px;
    }
 }