.aboutus {
    .hero-section {
        min-height: 90vh;
        background-image: url(/assets/aboutus-hero.jpg);
        background-size: cover;
        background-position: center bottom;
        color: #fff;
        padding-top:250px;
        h1{
            font-family: "runings";
            font-size: 35px;
        }
        hr {
            border-color: #fff;
            opacity: 1;
        }
        p  {
            font-size: 20px;
        }
        
    }
    h2 {
        font-size: 40px;
        font-weight: 600;
    }
    .features-box {
        background-color: #520F0F;
        border-radius: 30px;
    }
    .profile-name {
        color: var(--primary);
    }
    .team-designation {
        color: #848484;
        font-size: 26px;
    }
}

@media only screen and (max-width: 600px) { 
    .aboutus .hero-section {
        min-height: 80vh;
        padding-top: 150px;
    }
    .aboutus .hero-section h1 {
        font-size: 20px;
    }
    .aboutus .hero-section p {
        font-size: 16px;
    }
    .aboutus h2 {
        font-size: 24px;
    }
    .aboutus {
        .features-box {
            h4 {
                font-size: 20px;
            }
        }
    }
}