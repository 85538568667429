.footer {
    background-color: #000;
    .copyright{
        font-size: 14px;
    }
    h3 {
        color: var(--primary);
        font-size: 18px;
        font-weight: 500;
    }
    .links {
        a {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            display: block;
            cursor: pointer;
            padding-bottom: 4px;
        }
    }
    .followus {
        a {
            margin-right: 5px;
            img {
                width: 40px;
            }
        }
    }
}