.home-hero {
    min-height: 100vh;
    background-image: url(/assets/home-hero.jpg);
    background-size: cover;
    background-position-y: 17%;
    background-position-x: right;
    align-items: center;
    color: #fff;
    .red-line {
        width: 320px;
    }
    h1{
        font-family: "runings";
        font-size: 30px;
    }
    .logo {
        height: 24px;
    }
    p {
        color: #ffffff8f;
    }
}

.launching {
    position: relative;
    top: -78px;
    .box-bg {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.16) 0%, #151515 100%);
        box-shadow: 0 2px 4px 0 rgba(255,255,255,0.20);
        border-radius: 30px;
        padding: 30px;
        .title {
            font-size: 60px;
            font-weight: 800;
        }
        .time-box {
            min-width: 100px;
            text-align: center;
            .count {
                font-size: 40px;
                font-weight: 800; 
            }
            .days {
                font-size: 20px;
            }
        }
    }
}

.home-section2 {
    h2 {
        font-family: "runings";
        font-size: 50px;
    }
    .text1 {
        font-size: 22px;
        color: #ffffff8f;
    }
    .logo-text {
        font-family: "runings";
        color: #fff;
        span {
            color: var(--primary);
        }
    }
    .icon-section {
        img {
            max-height: 130px;
        }
    }
}

.details-section {
    .title {
        font-size: 30px;
        color: var(--primary);
        font-weight: 700;
    }
    p {
        color: #ffffff8f;
        font-size: 20px;
    }
    .title2 {
        font-weight: 600;
        font-size: 26px;
    }
    .p2 {
        font-size: 12px;
    }
}

.rewards{
    background-image: url(/assets/rewards-hero.png);
    background-size: cover;
    height: 800px;
    padding-top: 80px;
    border-radius: 25px;
    h3 {
        font-size: 40px;
        font-weight: 600;
    }
}

.one-platform {
    h3 {
        font-size: 40px;
        font-weight: 600;
        color: var(--primary);
    }
    .carousal_transition{
        .css-doq0dk{
            width: 66% !important;
            left: 20% !important;
            .css-1fzpoyk{
                // width: 265px !important;
                width: 0px !important;
                opacity: 1 !important;
                .title {
                    font-size: 30px;
                    font-weight: 600;
                  }
            }
        }
        .card-details {
            top: -120px;
            position: relative;
            padding: 0 10px;
        }
    }
    .carousal_transition>div {
        height: 750px;
    }
}

.become-part {
    background-image: url(/assets/become-part-bg.jpg);
    background-size: cover;
    height: 600px;
    h3 {
        font-size: 40px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 600px) {
    .home-hero {
        min-height: 80vh;
        padding: 100px 0;
        background-position-x: 80%;
    }
    .home-hero .red-line {
        width: 200px;
    }
    .home-hero .logo {
        height: 16px;
    }
    .home-hero h1 {
        font-family: "runings";
        font-size: 20px;
    }
    .home-hero .btn-primary {
        font-size: 12px;
    }
    .home-hero .btn-outline-primary {
        font-size: 12px;
    }
    .launching .box-bg .title {
        font-size: 32px;
        text-align: center;
        padding-bottom: 10px;
    }
    .home-section2 h2 {
        font-family: "runings";
        font-size: 20px;
    }
    .home-section2 .text1 {
        font-size: 16px;
    }
    .home-section2 .icon-section img {
        max-height: 80px;
    }
    .home-section2 .icon-section h5 {
        font-size: 0.8rem;
    }
    .details-section .title {
        font-size: 24px;
    }
    .details-section p {
        font-size: 16px;
    }
    .details-section .title2 {
        font-size: 18px;
    }
    .rewards h3 {
        font-size: 20px;
    }
    .rewards h3 {
        font-size: 18px;
    }
    .one-platform h3 {
        font-size: 24px;
    }
    .become-part {
        height: 300px;
        padding: 0 10px;
    }
    .become-part h3 {
        font-size: 24px;
    }
    .one-platform .carousal_transition {
        overflow: hidden;
    }
    .one-platform .carousal_transition > div {
        height: 550px;
    }
    .one-platform .carousal_transition .css-doq0dk {
        left: 0px !important;
        width: 100% !important;
    }
  }

@media only screen and (max-width: 400px) {
    .launching .box-bg .time-box {
        min-width: 78px;
    }
    .launching .box-bg .time-box .count {
        font-size: 30px;
    }

    .launching .box-bg .time-box .days {
        font-size: 15px;
    }
    .home-hero .btn-primary, .home-hero .btn-outline-primary {
        font-size: 11px;
        padding: 9px;
    }
}