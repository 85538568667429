.signup {
    padding: 60px 20px;
    background-color: #212121;
    h1 {
      color: var(--primary);
      font-family: "runings";
        font-size: 40px;
    }
    .Mui-checked .MuiSvgIcon-root {
      color: var(--primary);
    }
  }

  @media only screen and (max-width: 600px) { 
    .signup h1 {
        font-size: 20px;
        text-align: center;
    }
  }