.becomean-affiliate {
    padding-top: 150px;
    padding-bottom: 50px;
    min-height: calc(100vh - 157px);
    h1{
        font-family: "runings";
        font-size: 30px;
    }
    p {
        color: #ffffff8f;
    }
    .options {
        background-color: #520F0F;
        border-radius: 25px;
    }
}

.contact-box {
    background-color: #1A1A1A;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) { 
    .becomean-affiliate h1 {
        font-size: 26px;
    }
    .becomean-affiliate {
        .options {
            margin-bottom: 20px;
            img {
                height: 80px;
            }
        }
        h5 {
            font-size: 18px;
        }
    }
}