@import "./theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "runings";
  font-style: normal;
  font-weight: normal;
  src:
    local("runings"),
    url("./assets/fonts/runings.woff") format("woff");
}

body {
  font-family: "Roboto", serif;
}

a {
  color: var(--primary);
}

.error {
  color: var(--primary) !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  height: 50px;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  background-color: #0000006b !important;
  height: 50px;
}

.red-line {
  background-color: var(--primary);
  height: 6px;
  border-radius: 30px;
}

.nav-scrolled {
  background-color: #000 !important;
}

.logo-text {
  font-family: "runings";
  color: #fff;
  span {
    color: var(--primary);
  }
  .plus {
    font-weight: 700;
    font-style: italic;
  }
}

.carousel-caption {
  bottom: 40%;
}

.carousel {
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.5);
  border-radius: 20px;
}

.carousel-item {
  img {
    border-radius: 20px;
  }
}

.modal-close {
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 30px !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

fieldset {
  border-color: rgb(255 255 255 / 56%) !important;
}

.coming-page {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  .logo {
    height: 100px;
  }
  h1 {
    font-size: 70px;
    font-family: "runings";
    color: var(--primary);
  }
  .sub-text {
    font-size: 22px;
  }
  .count-box {
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 20px 30px;
    min-width: 150px;
    margin: 0 10px;

    .count {
      font-size: 50px;
      color: var(--primary);
      font-weight: 700;
    }

    .title {
      font-size: 24px;
      color: #fff;
      font-weight: 500;
    }
  }
}


@media only screen and (max-width: 600px) {
  .red-line {
    height: 4px;
  }
  .carousel-caption {
    bottom: 22%;
    h1 {
      font-size: 20px;
    }
  }
  .coming-page {
    .logo {
      height: 80px;
    }
    h1 {
      font-size: 40px;
    }
    .count-box {
      padding: 10px 5px;
      min-width: 65px;
      margin: 0 8px;

      .count {
        font-size: 25px;
      }

      .title {
        font-size: 14px;
      }
    }
  }
}
