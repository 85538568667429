.navbar {
    background-color: #0000003d !important;
    padding: 30px 0;
    .navbar-brand {
        img {
            height: 42px;
        }
    }
}
.navbar-toggler {
    border: 2px solid #d31b11;
    padding: 2px 8px;
    color: rgb(255 54 54 / 0%);
}
.nav-link {
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    text-transform: uppercase;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.nav-link:hover {
    color: var(--primary) !important;
}
.navSeleted {
    color: var(--primary) !important;
}
// .navbar {
//     .container {
//         background-color: #00000070;
//         border-radius: 5px;
//     }
// }

@media only screen and (max-width: 600px) {
    .navbar .navbar-brand img {
        height: 22px;
    }
    .offcanvas-header {
        .offcanvas-title {
            img {
                height: 22px;
            }
        }
    }
}

.nav-scrolled {
    background-color: #000 !important;
  }

